/********** 导航条 ******/
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex: 1;
}
.navbar {
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
}
.navbar .container {
  background: #ffffff;
}
.navbar .container .navbar-brand {
  margin-left: 30px;
}
.navbar .container .navbar-collapse {
  font-weight: 400;
  color: #666666;
  font-style: normal;
  text-transform: none;
}
.navbar .container .navbar-collapse .nav-item {
  margin: auto 16px;
}
.navbar .container .navbar-collapse .nav-item .nav-link {
  padding-right: 0;
  padding-left: 0;
  font-size: 15px;
}
.navbar .container .navbar-collapse .nav-item .dropdown-menu {
  color: #666666;
  font-size: 15px;
  min-width: auto !important;
}
.navbar .container .navbar-collapse .login {
  background: rgba(51, 113, 239, 0.1);
  color: #3371ef;
  font-size: 14px !important;
  border-radius: 4px 4px 4px 4px;
  width: 60px;
  text-align: center;
}
.navbar .container .navbar-collapse .register {
  background: #3371ef;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px !important;
  color: #ffffff;
  text-align: center;
  width: 88px;
}
/********** 右侧工具栏 ******/
.widget {
  position: fixed;
  bottom: 28%;
  right: 45px;
  width: 48px;
  zoom: 1;
  z-index: 100;
  text-align: center;
}
.widget li {
  width: 48px;
  height: 48px;
  background-color: #ebebee;
  margin-top: 8px;
  border-radius: 4px;
  color: #3371ef;
  list-style-type: none;
}
.widget li i {
  font-size: 28px;
  line-height: 48px;
  color: #3371ef;
}
.widget li div {
  box-sizing: border-box;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}
.widget li .widget-triangle {
  top: 12px;
  right: -11px;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 11px solid #e1e6ec;
  z-index: 1010;
}
.widget li .widget-triangle:after {
  content: '\20';
  top: -6px;
  right: 1px;
  position: absolute;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #fff;
  z-index: 1000;
}
.widget li .widget-triangle:before {
  content: '\20';
  width: 80px;
  height: 45px;
  top: -20px;
  right: -52px;
  position: absolute;
  background: rgba(0, 0, 0, 0);
}
.widget .widget-activity {
  background-color: #ff4d00;
}
.widget .widget-qr:hover .widget-qr-show {
  display: block;
  opacity: 1;
  transition: linear 0.2s;
  animation-name: fade-in;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}
.widget .widget-qr-show {
  width: 143px;
  height: auto;
  border-radius: 3px;
  background: #fff;
  right: 40px;
  position: absolute;
  text-align: center;
  border: 1px solid #e1e6ec;
  background-color: #f6f8fb;
  display: none;
  opacity: 0;
}
.widget .widget-qr-show .widget-triangle:after {
  border-left: 10px solid #f6f8fb !important;
}
.widget .widget-qr-show .widget-qrimg {
  width: 119px;
  height: 140px;
  float: left;
}
.widget .widget-qr-show .widget-qrimg img {
  width: 119px;
  height: 119px;
  float: left;
  margin: 12px 12px 5px 12px;
}
.widget .widget-qr-show .widget-qrtext {
  width: 100%;
  height: 30px;
  font-size: 12px;
  color: #7b89a6;
  background-color: #fff;
  float: left;
  bottom: 0;
  display: table;
  padding-bottom: 5px;
}
.widget .widget-qr-show .widget-qrtext span {
  display: table-cell;
  vertical-align: middle;
  font-size: 15px;
  color: #333;
  font-style: normal;
}
.widget .widget-phone:hover .widget-phone-show {
  display: block;
  opacity: 1;
  transition: linear 0.2s;
  animation-name: fade-in;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}
.widget .widget-phone-show {
  width: 160px;
  height: auto;
  border-radius: 3px;
  background: #fff;
  right: 40px;
  position: absolute;
  text-align: center;
  border: 1px solid #e1e6ec;
  display: none;
  opacity: 0;
}
.widget .widget-phone-show .widget-triangle:after {
  border-left: 10px solid #f6f8fb !important;
}
.widget .widget-phone-show span {
  float: left;
  font-size: 16px;
  color: #ea3700;
  font-style: normal;
  font-weight: 500;
}
.widget .widget-phone-show .widget-phone-number {
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
  color: #ea3700;
}
.widget .widget-totop-show {
  width: 84px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  background: #fff;
  right: 40px;
  position: absolute;
  text-align: center;
  border: 1px solid #e1e6ec;
  display: none;
  opacity: 0;
}
.widget .widget-totop-show span {
  font-size: 15px;
  color: #333;
  font-style: normal;
}
.widget .widget-totop:hover .widget-totop-show {
  display: block;
  opacity: 1;
  transition: linear 0.2s;
  animation-name: fade-in;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}
@keyframes fade-in {
  0% {
    opacity: 0.4;
    right: 82px;
  }
  100% {
    opacity: 1;
    right: 28px;
  }
}
/********** 页脚 ******/
.footer {
  background: #011b4f;
  border-radius: 0px 0px 0px 0px;
  font-weight: 400;
  font-size: 13px;
  color: #999999;
  float: left;
  margin-top: auto;
}
.footer a {
  color: #999999;
}
.footer .copy-right {
  margin: 22px auto 28px auto;
}
@media (max-width: 575.98px) {
  .login {
    margin: 8px 0;
  }
  .register {
    margin: 8px 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .login {
    margin: 8px 0;
  }
  .register {
    margin: 8px 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .login {
    margin: 8px 0;
  }
  .register {
    margin: 8px 0;
  }
}
